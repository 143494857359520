 /**
 *  PopStar Feature gates enabled
 * */
export const getEnabledFeatureGates = (): Array<string> =>  JSON.parse(document.getElementById("atoz-my-hr-app-root")?.dataset?.popstarFeatures ||
    "[]")

 export const authenticatePopStarFeature = (featureName: string) => {
     const popStartFeatures = JSON.parse(document.getElementById("atoz-my-hr-app-root")?.dataset?.popstarFeatures ||
         "[]");
     return popStartFeatures.includes(featureName);
 }